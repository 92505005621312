@media print {
    * {
        visibility: hidden;
        /* Hide everything by default */
    }

    .print-content,
    .print-content * {
        visibility: visible;
        /* Only show the content you want to print */
    }

    .print-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        border: none;
    }
}







/*======================
    404 page
=======================*/


.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {

    /* background-image: url('https://thelabsys.com/404.gif'); */
    height: 400px;
    margin-top: 20px;
    background-position: center;
    background-repeat: no-repeat;
}


h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
    border-radius: 10px;
}

.contant_box_404 {
    margin-top: -50px;
}






@media print {
    body * {
        visibility: hidden;
    }

    .print-container,
    .print-container * {
        visibility: visible;
    }

    .print-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
}